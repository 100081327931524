import { render, staticRenderFns } from "./ConfigImage.vue?vue&type=template&id=48a1e58e&scoped=true&"
import script from "./ConfigImage.vue?vue&type=script&lang=js&"
export * from "./ConfigImage.vue?vue&type=script&lang=js&"
import style0 from "./ConfigImage.vue?vue&type=style&index=0&id=48a1e58e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48a1e58e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VAvatar,VBtn,VDialog,VSnackbar})
