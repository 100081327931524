import apiWrapper from "@/apiWrapper";
import store from "@/store";

class dashboardTokenDataAccess{
  async createToken(tokenDescription){
    let result = await apiWrapper.createDashboardToken(tokenDescription);

    store.commit("createDashboardToken", result.data);

    return result.data;
  }

  async getTokens(){
    let result = await apiWrapper.getDashboardTokens();

    store.commit("setDashboardTokens", result.data);

    return store.getters.dashboardTokens;
  }

  async deleteToken(tokenId){
    await apiWrapper.deleteDashboardToken(tokenId);

    store.commit("deleteDashboardToken", tokenId);
  }
}

export default dashboardTokenDataAccess;