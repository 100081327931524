<template>
  <tr>
    <td>
      <v-tooltip left v-if="token.token[0] != '*'">
          <template v-slot:activator="{ on }">            
            <div ref="tokenText" @click="copyToken()" v-on="on" class="token-text" :style="{color: colors.PRIMARY}">{{token.token}}</div>
          </template>
          <span>Click to copy</span>
      </v-tooltip>
      <div class="token-text">{{token.token}}</div>
    </td>
    <td>{{token.description}}</td>
    <td class="text-center"><v-btn text @click="confirmationShown = true" color="red">Delete</v-btn></td>


    <v-dialog v-model="confirmationShown" width=500>
      <div class="delete-confirmation-dialog">
        <h1>Confirm Token Deletion</h1>
        <p>Are you sure you want to delete {{token.description}}? 
          <br/><br/>Any agents using this token will no longer be able to access the api.</p>
        <v-btn :color="colors.PRIMARY" class="white--text mr-1" @click="deleteToken()">Delete</v-btn>
        <v-btn :color="colors.PRIMARY" class="white--text ml-1" @click="confirmationShown = false">Cancel</v-btn>
      </div>
    </v-dialog>

  </tr>
</template>

<script>
import dashboardTokenDataAccess from '../../../dataAccess/dashboardTokenDataAccess';
import colors from "@/colors";

export default {
  name: "accesstoken",
  props: ["token"],
  data() {
    return {
      colors,
      tokenDAO: new dashboardTokenDataAccess(),
      confirmationShown: false
    }
  },
  methods: {
    async deleteToken(){
      await this.tokenDAO.deleteToken(this.token.id);
      this.confirmationShown = false;
    },
    copyToken(){
      var range = document.createRange();
      range.selectNode(this.$refs.tokenText);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
    }
  }
}
</script>

<style scoped>
.token-description{
  margin-left: -50px;
}
.delete-btn{
  color: red !important;
  cursor: pointer;
  user-select: none;
}

.padded-tile{
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.padded-tile:last-child{
  border-bottom: none;;
}

.token-text{
  cursor: pointer;
}

.delete-confirmation-dialog{
  background-color: white;
  padding: 20px;
  border-radius: 2px;
}

.delete-confirmation-dialog p{
  margin: 25px 0;
}
</style>