<template>
  <div class="lunches-container">
      <div class="title-bar">
        <h1>Lunch & Learns</h1>
        <div>
          <v-btn :color="colors.PRIMARY" class="white--text upload-button" @click="clearLunches">Clear Past Lunches</v-btn>
          <v-btn :color="colors.PRIMARY" dark class="upload-button" @click="addLunch()">Add</v-btn>

          <v-btn :color="colors.PRIMARY" class="white--text upload-button" @click="selectFile">Upload</v-btn>
        </div>
        <input v-show="false" id="lunchUpload" type="file" accept=".csv" @change="uploadFile($event.target.name, $event.target.files)">
      </div>
      <div class="current-lunches-container">
        <v-progress-circular
            v-if="isLoadingList"
            indeterminate
            :color="colors.PRIMARY"
            class="lunches-progress"
            :size="50"
            :width="3"
        ></v-progress-circular>

        <v-simple-table v-else fixed-header height="500px">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">Date</th>
                        <th class="text-left">Team</th>
                        <th class="text-left">Restaurant</th>
                        <th class="text-left">Food</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="lunch in sortedLunchList">
                        <LunchDay 
                            :key="lunch.id" 
                            :lunch="lunch"                             
                            class="left-aligned" 
                            :ref="`lunch_${lunch.id}`" />
                    </template>
                </tbody>
            </template>
        </v-simple-table>
       
        <v-snackbar
          v-model="snackbarShown"
          :timeout="5000"
          :color="snackbarColor"
        >{{snackbarMessage}}</v-snackbar>
      </div>
  </div>
</template>

<script>
import colors from "@/colors"
import LunchDay from "./LunchDay.vue"
import lunchDataAccess from "../../../dataAccess/lunchDataAccess";
import {mapGetters, mapActions} from "vuex";

export default {
  name: "lunches",
  components: {
    LunchDay
  },
  data(){
      return {
          colors,
          isLoadingList: true,
          lunchDAO: new lunchDataAccess(),
          snackbarShown: false,
          snackbarColor: '',
          snackbarMessage: ''
      };
  },
  computed: {
      ...mapGetters({currentLunches: "lunchList"}),
      sortedLunchList() {
          return [...this.currentLunches].sort((a,b) => {
            if (a.eatDate < b.eatDate) {
                return -1;
            }
            if (a.eatDate > b.eatDate) {
                return 1;
            }
            return 0;
        })
      }
  },
  methods: {
      ...mapActions({ addLunchToList: "addLunch", removeLunchFromList: "removeLunch"}),

      selectFile(){
          document.getElementById("lunchUpload").click();
      },
      async uploadFile(name, files){
        this.isLoadingList = true;
        try{
            await this.lunchDAO.uploadLunches(files[0]);
            await this.lunchDAO.getLunches();
            this.snackbarMessage = "Upload successful.";
            this.snackbarColor = 'green';
        }
        catch{
            this.snackbarMessage = "Error uploading spreadsheet. Please make sure the column formatting is correct.";
            this.snackbarColor = 'red';
        }

        this.isLoadingList = false;
        document.getElementById("lunchUpload").value = "";
        this.snackbarShown = true;
      },
      async clearLunches() {
          this.isLoadingList = true;
          var today = new Date();
          today.setHours(0, 0, 0, 0);

          let oldLunches = this.currentLunches.filter((lunch) => {
              return new Date(lunch.eatDate).getTime() < today.getTime();
          });

          for(let i = 0; i < oldLunches.length; i++){
              try{
                  let lunchId = oldLunches[i].id;
                  await this.lunchDAO.deleteLunch(lunchId);
                  this.removeLunchFromList(lunchId);
              }catch{
                this.snackbarMessage = 'Error deleting lunches, please try again later.';
                this.snackbarColor = 'red'
                this.snackbarShown = true;
              } 
          }
          
          this.isLoadingList = false;
      },
      addLunch() {
        let newId = -1;

        if (this.currentLunches.length > 0) {
            let currentMinId = Math.min(...this.currentLunches.map(l => l.id));
            newId = currentMinId > 0 ? -currentMinId : currentMinId - 1;
        } 

        const newLunch = {
            id: newId, // need to set a value for the ID so the :key prop can bind to a distinct value
            eatDate: '2021-05-01',
            teamName: '',
            restaurantName: '',
            foodName: ''
        }
        this.addLunchToList(newLunch);

        setTimeout(() => {
            this.$refs[`lunch_${newId}`][0].$el.scrollIntoView(false);
        }, 50)
      },
    
      async initLunches(){
          this.isLoadingList = true;
          await this.lunchDAO.getLunches();
          this.isLoadingList = false;
      },
    
  },
  mounted: async function(){
      await this.initLunches();
  }
};
</script>

<style scoped>
.title-bar {
    justify-content: space-between;
    align-items: center;
}

.header-line{
    border-bottom: 1px solid #ccc;
}

.upload-button{
    margin: 0px;
    margin-right: 15px;
}

.lunches-container{
    margin: 20px;
    box-shadow: 0px 0px 5px #777777;
    min-height: 30px;
    background-color: white;
}

.lunch-list{
    max-height: 500px;
    overflow-y: scroll;
}

.bold-text{
    font-weight: 700;
}

.left-aligned{
    text-align: left;
}

.header-line{
    margin-top: 5px;
}

.lunches-progress{
    margin-top: 100px;
    margin-bottom: 100px;
}
</style>