<template>
    <div class="config-container">
        <div class="title-bar">
            <h1>Config</h1>
        </div>
        <div class="config-options">
            <div class="config-option">
                <label>Slideshow Interval (seconds)</label>
                <v-autocomplete
                    class="config-select"
                    :items="intervalOptions"
                    :placeholder="'Select an interval'" 
                    :color="colors.PRIMARY"
                    :loading="isLoading"
                    v-on:change="displayIntervalSelected"
                    v-model="config.imageDisplayInterval"/>
            </div>
        </div>
    </div>
</template>

<script>
import colors from "@/colors";
import dashboardConfigDataAccess from "../../../dataAccess/dashboardConfigDataAccess";

export default {
    name: "config",
    data(){
        return {
            config: {
                imageDisplayInterval: null
            },
            intervalOptions: [5, 10, 15, 20, 25, 30],
            configDAO: new dashboardConfigDataAccess(),
            colors,
            isLoading: true
        }
    },
    methods:{
        async initConfig(){
            this.config = await this.configDAO.getConfig();
            this.isLoading = false;
        },
        async displayIntervalSelected(){
            this.config = await this.configDAO.updateConfig(this.config);
        }
    },
    mounted: async function(){
        await this.initConfig();
    }
}
</script>

<style scoped>
.config-container{
    margin: 20px;
    box-shadow: 0px 0px 5px #777777;
    min-height: 30px;
    background-color: white;
    text-align: left;
}

.config-options{
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 15px;
}

.config-select{
    margin-top: -10px;
}
</style>