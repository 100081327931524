<template>
  <div>
    <div class="title-bar">
      <h1>Control Panel</h1>
    </div>
    <div class="config-sections">
      <div class="left-section">
        <Images class="config-flex-child"/>
        <Config class="config-flex-child"/>
      </div>
      <div class="right-section">
        <Lunches class="config-flex-child"/>
        <AccessTokens class="config-flex-child"/>
      </div>
    </div>
    <div>
    </div>
  </div>
</template>

<script>
import Config from "./components/Config.vue";
import Images from "./components/Images.vue";
import Lunches from "./components/Lunches.vue";
import AccessTokens from "./components/AccessTokens.vue";

export default {
  name: "breakroomcontrolpanel",
  components: {
    Config,
    Images,
    Lunches,
    AccessTokens
  },
};
</script>

<style scoped>
.config-sections{
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
    flex-wrap:wrap;
    margin-bottom: 200px;
}

.config-sections *{
    flex-grow: 1;
}
</style>