<template>
  <div class="images-container">
      <div class="title-bar">
        <h1>Slideshow Images</h1>
        <v-btn :color="colors.PRIMARY" class="white--text upload-button" @click="selectFile">Upload</v-btn>
        <input v-show="false" multiple id="imageUpload" type="file" @change="uploadFile($event.target.name, $event.target.files)" accept=".png, .jpeg, .jpg, .gif">
      </div>

      <v-progress-circular
        v-if="isLoadingList"
        indeterminate
        :color="colors.PRIMARY"
        class="images-progress"
        :size="50"
        :width="3"
      ></v-progress-circular>

      <v-simple-table v-else fixed-header height="500px">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Image</th>
              <th class="text-left">Name</th>
              <th class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="image in currentImages">
              <ConfigImage :image="image" :key="image.id" class="left-aligned"/>
            </template>
          </tbody>
        </template>
      </v-simple-table>

      <v-snackbar
        v-model="snackbarShown"
        :timeout="5000"
        :color="snackbarColor"
      >{{snackbarMessage}}</v-snackbar>
  </div>
</template>

<script>
import colors from "@/colors"
import ConfigImage from "./ConfigImage.vue"
import imageDataAccess from "../../../dataAccess/imageDataAccess";

export default {
  name: "images",
  components: {
    ConfigImage
  },
  data(){
      return {
          colors,
          currentImages: [],
          imageDAO: new imageDataAccess(),
          isLoadingList: true,
          snackbarShown: false,
          snackbarColor: '',
          snackbarMessage: ''
      };
  },
  methods: {
      selectFile(){
          document.getElementById("imageUpload").click();
      },
      async uploadFile(name, files){
          this.isLoadingList = true;
          try{
            await this.imageDAO.uploadImages(files);
            this.snackbarMessage = 'Images uploaded successfully';
            this.snackbarColor = 'green';
          }
          catch{
            this.snackbarMessage = 'Upload failed. Please try again later.';
            this.snackbarColor = 'red';
          }

          document.getElementById("imageUpload").value = "";
          this.snackbarShown = true;
          this.isLoadingList = false;
          
      },
      async initImages(){
          try{
            this.currentImages = await this.imageDAO.getImages();
          }
          catch{
              this.snackbarMessage = 'Error loading images. Please try again later.';
              this.snackbarColor = 'red';
              this.snackbarShown = true;
          }
          this.isLoadingList = false;
      }
  },
  mounted: async function(){
      await this.initImages();
  }
};
</script>

<style scoped>
.title-bar {
    justify-content: space-between;
    align-items: center;
}

.upload-button{
    margin: 0px;
    margin-right: 15px;
}

.header-line{
    border-bottom: 1px solid #ccc;
}

.images-container{
    margin: 20px;
    box-shadow: 0px 0px 5px #777777;
    min-height: 30px;
    background-color: white;
}

.image-list{
    max-height: 500px;
    overflow-y: scroll;
}

.bold-text{
    font-weight: 700;
}

.left-aligned{
    text-align: left;
}

.header-line{
    margin-top: 5px;
}

.images-progress{
    margin-top: 100px;
    margin-bottom: 100px;
}
</style>