import apiWrapper from "@/apiWrapper";
import store from "@/store";

class lunchDataAccess {
  async getLunches(){
    let response = await apiWrapper.getLunches();    
    store.commit("setLunches", response.data);
    return store.getters.lunchList;
  }

  async getLunchesWithAccessToken(accessToken){
    let response = await apiWrapper.getLunchesWithAccessToken(accessToken);
    store.commit("setLunches", response.data);
    return store.getters.lunchList;
  }

  async uploadLunches(lunchFile){
    var lunchesBase64 = await this.getLunchBytes(lunchFile);
    await apiWrapper.uploadLunches(lunchesBase64);
  }

  async deleteLunch(lunchId){
    await apiWrapper.deleteLunch(lunchId);
  }

  async saveLunch(lunch) {
    let response = lunch.id > 0 
      ? await apiWrapper.editLunch(lunch)
      : await apiWrapper.addLunch(lunch);
      
    return response.data;    
  }

  async getLunchBytes(lunchFile){
    var fileReadPromise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(lunchFile);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

    return await fileReadPromise;
  }
}

export default lunchDataAccess