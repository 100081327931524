<template>
  <tr ref="form" :class="{'new-record': editedLunch.id < 0, 'edit-record': editedLunch.id > 0}">
    <template v-if="editedLunch">
      <td class="pt-4">
        <v-text-field type="date" v-model="editedLunch.eatDate" placeholder="Date" persistent-hint :hint="editedLunch.id > 0 ? formatDateForDisplay(lunch.eatDate) : ''" required :rules="[() => !!editedLunch.eatDate || 'Date is required']" outlined dense></v-text-field>
      </td>
      <td class="pt-4">
        <v-text-field v-model="editedLunch.teamName" placeholder="Team" outlined dense required persistent-hint :hint="editedLunch.id > 0 ? lunch.teamName : ''" :rules="[() => !!editedLunch.teamName || 'Team name is required']"></v-text-field>
      </td>
      <td class="pt-4">
        <v-text-field v-model="editedLunch.restaurantName" placeholder="Restaurant" persistent-hint :hint="editedLunch.id > 0 ? lunch.restaurantName : ''" outlined dense clearable></v-text-field>  
      </td>
      <td class="pt-4">
        <v-text-field v-model="editedLunch.foodName" placeholder="Food" outlined persistent-hint  :hint="editedLunch.id > 0 ? lunch.foodName : ''" dense clearable></v-text-field>  
      </td>
      <td class="text-right">
        <v-btn color="success" icon @click.stop="save()" :loading="saving" title="Save" :disabled="!valid"><v-icon>mdi-content-save</v-icon></v-btn>
        <v-btn color="default" icon @click.stop="cancel()" title="Cancel" :disabled="saving"><v-icon>mdi-close</v-icon></v-btn>
      </td>
    </template>

    <template v-else>
      <td>{{formattedDate}}</td>
      <td>{{lunch.teamName}}</td>
      <td>{{lunch.restaurantName}}</td>
      <td>{{lunch.foodName}}</td>
      <td class="text-right">
          <v-btn color="warning" icon @click.stop="editLunch()" title="Edit"><v-icon>mdi-pencil</v-icon></v-btn>
          <v-btn color="red" icon @click.stop="confirmationShown = true" title="Delete"><v-icon>mdi-delete</v-icon></v-btn>
      </td>
    </template>

    <v-dialog v-model="confirmationShown" width=500>
      <div class="delete-confirmation-dialog">
        <h1>Confirm Lunch Deletion</h1>
        <p>Are you sure you want to delete {{lunch.restaurantName}} {{lunch.foodName}} on {{lunch.eatDate}}?</p>
        <v-btn :color="colors.PRIMARY" class="white--text mx-4" @click="deleteLunch()" :loading="deleting">Delete</v-btn>
        <v-btn :color="colors.PRIMARY" class="white--text mx-4" @click="confirmationShown = false" :disabled="deleting">Cancel</v-btn>
      </div>
    </v-dialog>
  </tr>
</template>

<script>
import lunchDataAccess from '../../../dataAccess/lunchDataAccess'
import colors from "@/colors"
import moment from 'moment';
import { mapActions} from "vuex";

export default {
  name: "LunchDay",
  props: [ "lunch" ],
  data() {
    return {
      colors,
      imageDAO: new lunchDataAccess(),
      confirmationShown: false,
      editMode: false,
      formattedDate: '',

      editedLunch: '',
      saving: false,
      deleting: false,
    }
  },
  computed: {
    isEditMode() {
      return !this.lunch.id || this.editedLunch;
    },
    valid() {
      return this.eatDateIsValid && this.teamNameIsValid;
    },
    eatDateIsValid() {
      const isValid = this.editedLunch.eatDate && moment(this.editedLunch.eatDate, 'YYYY-MM-DD').isValid();
      return isValid;
    },
   
    teamNameIsValid() {
      return this.editedLunch.teamName && this.editedLunch.teamName.length > 0;
    },
    teamNameRules() {
      return t => (t || '').length > 0 || 'Team name is required';
    }
    
  },
  methods: {
    ...mapActions({ addLunchToList: "addLunch", removeLunchFromList: "removeLunch" }),
    reset() {
      this.confirmationShown = false;
      this.editMode = false;

      this.editedLunch = '';
      this.saving = false;
      this.deleting = false;
    },
    formatDateForDisplay(date) {
        return moment(date).format('M/D/YYYY')
    },
    async deleteLunch(){
      try {
        this.deleting = true
        await this.imageDAO.deleteLunch(this.lunch.id);
        this.removeLunchFromList(this.lunch.id);
      } finally {
        this.deleting = false;
      }
    },
    async editLunch() {
      this.editedLunch = {
        id: this.lunch.id,
        eatDate: moment(this.lunch.eatDate).format('YYYY-MM-DD'),
        teamName: this.lunch.teamName,
        restaurantName: this.lunch.restaurantName,
        foodName: this.lunch.foodName
      };

      this.editMode = true;
    },
    cancel() {
      console.log({'Cancel': this.lunch});
      if (this.lunch.id < 0) {
          this.removeLunchFromList(this.lunch.id);
      }

      this.reset();
    },
    async save() {
        this.saving = true;
        let updated = await this.imageDAO.saveLunch(this.editedLunch);
        this.removeLunchFromList(this.lunch.id);
        this.addLunchToList(updated);
        this.formattedDate = this.formatDateForDisplay(updated.eatDate);
        this.reset();
    }
  },
  watch: {
    formattedDate() {
      this.lunch.eatDate = moment(this.formattedDate).format('YYYY-MM-DD');
    }
  },
  mounted() {
    if (this.lunch.id < 0) {
      this.editLunch();
    }
    this.formattedDate = this.formatDateForDisplay(this.lunch.eatDate);
  }
}
</script>

<style scoped>
.delete-btn{
  color: red !important;
  cursor: pointer;
  user-select: none;
}

.padded-tile{
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.padded-tile:last-child{
  border-bottom: none;;
}

.delete-confirmation-dialog{
  background-color: white;
  padding: 20px;
  border-radius: 2px;
}

.delete-confirmation-dialog p{
  margin: 25px 0;
}

.edit-record,
.new-record {
  background-color: #00FF0006 !important;
  margin-top: -1px;
}

.edit-record td:first-child,
.new-record td:first-child {
  border-left: 2px solid green !important;
}

.edit-record td:last-child,
.new-record td:last-child {
  border-right: 2px solid green !important;
}

.edit-record td,
.new-record td {
  border-top: 2px solid green !important;
  border-bottom: 2px solid green !important;
}
</style>