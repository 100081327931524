import apiWrapper from "@/apiWrapper";
import store from "@/store";

class dashboardConfigDataAccess {
    async getConfig(){
        let response = await apiWrapper.getDashboardConfig();
        store.commit("setConfig", response.data);

        return store.getters.config;
    }

    async getConfigWithAccessToken(accessToken){
        let response = await apiWrapper.getDashboardConfigWithAccessToken(accessToken);
        store.commit("setConfig", response.data);

        return store.getters.config;
    }

    async updateConfig(config){
        let response = await apiWrapper.putDashboardConfig(config);
        store.commit("setConfig", response.data);

        return store.getters.config;
    }
}

export default dashboardConfigDataAccess