<template>
  <tr>
    <td class="py-1"><v-avatar size=50><img :src="image.imageBytes"/></v-avatar></td>
    <td>{{image.fileName}}</td>
    <td class="text-center"><v-btn text @click="confirmationShown = true" color="red">Delete</v-btn></td>

    <v-snackbar
      v-model="snackbarShown"
      :timeout="5000"
      color="red"
    >Error deleting image.</v-snackbar>

    <v-dialog v-model="confirmationShown" width=500>
      <div class="delete-confirmation-dialog">
        <h1>Confirm Image Deletion</h1>
        <p>Are you sure you want to delete {{image.fileName}}?</p>
        <v-btn :color="colors.PRIMARY" class="white--text mr-1" @click="deleteImage()">Delete</v-btn>
        <v-btn :color="colors.PRIMARY" class="white--text ml-1" @click="confirmationShown = false">Cancel</v-btn>
      </div>
    </v-dialog>
  </tr>
</template>

<script>
import imageDataAccess from '../../../dataAccess/imageDataAccess';
import colors from "@/colors";

export default {
  name: "ConfigImage",
  props: [ "image" ],
  data() {
    return {
      colors,
      imageDAO: new imageDataAccess(),
      snackbarShown: false,
      confirmationShown: false
    }
  },
  methods: {
    async deleteImage(){
      try{
        await this.imageDAO.deleteImage(this.image.id);
      }
      catch{
        this.snackbarShown = true;
      }
      this.confirmationShown = false;
    }
  }
}
</script>

<style scoped>
.file-name{
  margin-left: -50px;
}
.delete-btn{
  color: red !important;
  cursor: pointer;
  user-select: none;
}

.padded-tile{
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.padded-tile:last-child{
  border-bottom: none;
}

.delete-confirmation-dialog{
  background-color: white;
  padding: 20px;
  border-radius: 2px;
}

.delete-confirmation-dialog p{
  margin: 25px 0;
}
</style>