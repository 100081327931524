import apiWrapper from "@/apiWrapper";
import store from "@/store";

class imageDataAccess {
  async getImages(){
    let response = await apiWrapper.getImages();
  
    store.commit("setImages", response.data);

    return store.getters.imageList;
  }

  async getImagesWithAccessToken(accessToken){
    let response = await apiWrapper.getImagesWithAccessToken(accessToken);
  
    store.commit("setImages", response.data);

    return store.getters.imageList;
  }

  async uploadImages(imageFiles){
    let imageUploadArray = [];
    for(let i = 0; i < imageFiles.length; i++){
      var imageFile = imageFiles[i];

      var imageBytes = await this.getImageBytes(imageFile);
      var imageUploadObject = {
        imageBytes: imageBytes,
        fileName: imageFile.name
      };

      imageUploadArray.push(imageUploadObject);
    }

    let response = await apiWrapper.uploadImages(imageUploadArray);

    response.data.forEach((image) => {
      store.commit("addImage", image);
    })

    return store.getters.imageList;
  }

  async deleteImage(imageId){
    let response = await apiWrapper.deleteImage(imageId);

    store.commit("removeImage", imageId);

    return response.data;
  }

  async getImageBytes(imageFile){
    var fileReadPromise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

    return await fileReadPromise;
  }
}

export default imageDataAccess