<template>
  <div class="tokens-container">
    <div class="title-bar">
      <h1>Access Tokens</h1>
      <div class="actions">
        <div v-if="isCreating" class="token-inputs">
          <v-text-field v-model="tokenDescription" placeholder="Access token Description" :color="colors.PRIMARY"></v-text-field>
          <v-btn
            :color="colors.PRIMARY"
            class="white--text upload-button"
            @click="createToken"
          >Create</v-btn>
        </div>
        <v-btn
          v-else
          :color="colors.PRIMARY"
          class="white--text upload-button"
          @click="isCreating = true;"
        >New Token</v-btn>
      </div>
    </div>
    <div class="current-tokens-container">
      <v-progress-circular
        v-if="isLoading"
        indeterminate
        :color="colors.PRIMARY"
        class="tokens-progress"
        :size="50"
        :width="3"
      ></v-progress-circular>

      <v-simple-table v-else fixed-header height="500px">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Value</th>
              <th class="text-left">Description</th>
              <th class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="token in tokens">
              <AccessToken :token="token" :key="token.id" class="left-aligned" />
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <v-snackbar
      v-model="snackbarShown"
      :timeout="8000"
      color="green"
    >Token Created. Copy the token for later use, we won't show it to you again.</v-snackbar>
  </div>
</template>

<script>
import colors from "@/colors";
import dashboardTokenDataAccess from "../../../dataAccess/dashboardTokenDataAccess";
import AccessToken from "./AccessToken.vue";

export default {
  name: "accesstokens",
  components: {
    AccessToken
  },
  data() {
    return {
      tokenDAO: new dashboardTokenDataAccess(),
      tokens: [],
      isLoading: true,
      isCreating: false,
      tokenDescription: "",
      colors,
      snackbarShown: false
    };
  },
  methods: {
    async initTokens() {
      this.tokens = await this.tokenDAO.getTokens();
      this.isLoading = false;
    },
    async createToken() {
      await this.tokenDAO.createToken(this.tokenDescription);

      this.tokenDescription = "";
      this.isCreating = false;
      this.snackbarShown = true;
    }
  },
  mounted: async function() {
    await this.initTokens();
  }
};
</script>

<style scoped>
.title-bar {
  justify-content: space-between;
  align-items: center;
}

.tokens-container {
  margin: 20px;
  box-shadow: 0px 0px 5px #777777;
  min-height: 30px;
  background-color: white;
}

.header-line {
  border-bottom: 1px solid #ccc;
}

.token-list {
  max-height: 500px;
  overflow-y: scroll;
}

.token-inputs{
  display: flex;
  align-items: center;
}

.token-inputs *:first-child{
  margin-right: 25px;
  width: 250px;
}

.bold-text {
  font-weight: 700;
}

.left-aligned {
  text-align: left;
}

.header-line {
  margin-top: 5px;
}

.tokens-progress {
  margin-top: 100px;
  margin-bottom: 100px;
}
</style>